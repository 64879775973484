import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import BillingController from 'domain/controllers/Billing.controller';
import * as actions from "domain/actions/secretShopper.action";
import * as constants from "domain/constants/secretShopper.constant";
import * as types from "domain/types/secretShopper.type";
import * as reducers from "domain/reducers/secretShopper.reduce";
import { Result } from 'domain/types/other.type';
import { getAccount } from 'domain/reducers/account.reduce';
import { NorrController } from 'domain/controllers/Response.controller';
import ShopperController from 'domain/controllers/Shopper.controller';
import { setTelegramToken } from 'domain/actions/account.action';
import { getCurrentCity } from 'domain/reducers/city.reduce';
import { postToken } from './notifications.saga';
import { download } from 'utils/dowload';
import { orderBy } from 'lodash';
import { IPagination } from './restaurant.saga';
import { setTasksMain } from 'domain/actions/main.action';

const api = new ApiController();
const norr = new NorrController();
const billing = new BillingController();
const shopper = new ShopperController();

export async function getBankListFetch(): Promise<Result<any>> {
  return await billing.get(`/payout/banks`);
}

export async function getRanksFetch(): Promise<Result<any>> {
  return await shopper.get(`/rank`);
}

export async function getTasksByIdFetch(taskId: string): Promise<Result<types.ITask>> {
  return await shopper.get(`/tasks/${taskId}`);
}

export async function postParticipation(taskId: string): Promise<Result<any>> {
  return await shopper.post(`/tasks/task/${taskId}/participation`, {});
}

export async function postTaskCancel(taskId: string): Promise<Result<void>> {
  return await shopper.put(`/tasks/task/${taskId}/cancel`, {});
}

export async function postTaskClientFetch(taskId: string): Promise<Result<any>> {
  return await shopper.post(`/tasks/task/${taskId}/confirm`, {});
}

export async function checkSubscribeFetch(): Promise<Result<void>> {
  return await shopper.get(`/subscribe/client`);
}

export async function subscribeShopperFetch(params: types.ISubscribe): Promise<Result<string>> {
  return await shopper.post(`/subscribe`, params);
}

export async function putSubscribeShopperFetch(id: string, params: types.ISubscribe): Promise<Result<void>> {
  return await shopper.put(`/subscribe/${id}`, params);
}

export async function getTasksFetch(cityId: string): Promise<Result<Array<types.ITask>>> {
  return await shopper.get(`/tasks/city/${cityId}`);
}

export async function getTasksFilterFetch(filters: { cities: string[] }, pagination: IPagination): Promise<Result<Array<types.ITask>>> {
  return await api.get(`/main/shopper/tasks`, { filters, pagination });
}

export async function getMyTasksFetch(): Promise<Result<Array<types.ITask>>> {
  return await shopper.get(`/tasks/client`);
}

export async function getTaskArchiveFetch(): Promise<Result<Array<types.ITask>>> {
  return await shopper.get(`/tasks/client/archive`);
}

export async function getReportTaskFetch(taskId: string): Promise<Result<Array<any>>>{
  return await api.get(`/shopper/tasks/${taskId}/report`);
}

export async function postReporterFetch(taskId: string, values: any): Promise<any> {
  return await api.post(`/shopper/tasks/${taskId}/complete`, values);
}

export async function putReporterFetch(reportId: string, values: any): Promise<Result<void>> {
  return await api.put(`/shopper/tasks/report/${reportId}/complete`, values);
}

export async function postCardFetch(taskId: string, values: any): Promise<Result<void>> {
  return await api.post(`/shopper/tasks/${taskId}/response_remuneration`, values);
}

export async function postFileReceiptFetch(file: any): Promise<Result<void>> {
  const fd = new FormData();
  fd.append('image', file, file.name)
  return await api.post(`/shopper/tasks/upload/receipt`, fd);
}

export async function postFilePhotosFetch(files: any): Promise<Result<void>> {
  const fd = new FormData();
  files.forEach( (image: any) => {
    fd.append('gallery', image, image.name)
  })
  return await api.post(`/shopper/tasks/upload/photos`, fd)
}

export async function archiveMyTaskFetch(taskId:string): Promise<Result<void>> {
  return await shopper.put(`/tasks/task/${taskId}/archive`, {});
}

export function* getBankList(): any {
  const response = yield call(getBankListFetch);

  yield call(norr.processing, response, function *(){
    yield put(actions.setBankList(response.value))
  })
}

export function* getRanks(): any {
  const response = yield call(getRanksFetch);

  yield call(norr.processing, response, function *(){
    yield put(actions.setRanks(response.value))
  })
}

export function* getTaskById(action: any): any {
  const response = yield call(getTasksByIdFetch, action.payload.taskId);

  yield call(norr.processing, response, function *(){
    yield put(actions.setCurrentTask(response.value))
  })
}

export function* getTasks(isLoading?: boolean):any{
  if( isLoading ) yield put(actions.reqSecretShopper(true))

  const city = yield select(getCurrentCity)
  if(!city) return null;
  const response = yield call(getTasksFetch, city.id)
  
  if(yield call(norr.processing, response, function *(){})) {
    yield put(actions.setTasks(response.value))
  }  

  yield put(actions.reqSecretShopper(false))
}  

export function* getTasksByFilter(action: any):any{
  const {page, pageSize = 25, isLoading} = action.payload;
  const pagination = {page: page, pageSize: pageSize}
  const city = yield select(getCurrentCity);
  const filters = {
    cities: city ? [] : [], // clear filter
  }
  
  if( isLoading ) yield put(actions.reqSecretShopper(true))

  const response = yield call(getTasksFilterFetch, filters, pagination)
  
  if(yield call(norr.processing, response, function *(){})) {
    yield put(setTasksMain(response.value))
  }  

  yield put(actions.reqSecretShopper(false))
}

export function* getMyTasks(): any{
  yield put(actions.reqSecretShopper(true))

  const response = yield call(getMyTasksFetch)

  yield call(norr.processing, response, function *(){
    try {
      const sort = orderBy(response.value, ['createdAt'], ['desc'])
      yield put(actions.setMyTasks(sort)) 
    } catch (error) {
      yield put(actions.setMyTasks(response.value))
    }
  })

  yield put(actions.reqSecretShopper(false))
}

export function* getTaskArchive(): any{
  yield put(actions.reqSecretShopper(true))

  const response = yield call(getTaskArchiveFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setTaskArchive(response.value)) 
  })

  yield put(actions.reqSecretShopper(false))
}

export function* postRaffle(action: any): any{
  const taskId = action.payload.taskId;
  const cb = action.payload.cb;

  yield put(actions.reqTaskById(taskId, true))

  const response = yield call(postParticipation, taskId)

  yield call(norr.processing, response, function *(){
    yield cb()
    yield call(getTasks, false)
  })

  yield put(actions.reqTaskById(taskId, false))

}

export function* taskCancel(action: any): any{
  const taskId = action.payload.taskId;
  const cb = action.payload.cb;

  yield put(actions.reqTaskById(taskId, true))

  const response = yield call(postTaskCancel, taskId)

  yield call(norr.processing, response, function *(){
    yield cb()
    yield call(getTasks, false)
  })

  yield put(actions.reqTaskById(taskId, false))
}

export function* postMyTask(action: any): any{
  yield put(actions.reqSecretShopper(true))
  const taskId = action.payload.taskId;
  const callback = action.payload.cb

  const response = yield call(postTaskClientFetch, taskId)

  yield call(norr.processing, response, function *(){
    yield call(getTasks, false)
    callback();
  })

  yield put(actions.reqSecretShopper(false))
}

export function* postReporter(action: any): any{
  const {values} = action.payload;
  const account = yield select(getAccount);
  const task = yield select(reducers.getCurrentTask);

  yield put(actions.reqSecretShopper(true))

  const resUploadReceipt = yield call(postFileReceiptFetch, values.file)

  yield call(norr.processing, resUploadReceipt, function *(){ })
  
  if(resUploadReceipt.type === 'error') {
    yield put(actions.reqSecretShopper(false))
    return;
  }

  if(values.photos.length > 0) {
    const resUploadPhotos = yield call(postFilePhotosFetch, values.photos)

    yield call(norr.processing, resUploadPhotos, function *(){ })

    if(resUploadPhotos.type === 'error') {
      yield put(actions.reqSecretShopper(false))
      return;
    }

    values.photos = JSON.stringify(resUploadPhotos.value)

  }else if(Array.isArray(values.photos)){
    values.photos = JSON.stringify(values.photos)
  }

  values.taskId = task.id
  values.accountId = account.accountId
  values.receipt = resUploadReceipt.value
// console.log(values)
  const response = yield call(postReporterFetch, task.id, values)

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('report', false))
    yield call(getMyTasks)
  }, 'Отчет отправлен')

  yield put(actions.reqSecretShopper(false))
  
}

export function* putReportTask(action: any): any{
  const {reportId, values, isFile } = action.payload;

  yield put(actions.reqSecretShopper(true))

  if(isFile) {
    const resUploadReceipt = yield call(postFileReceiptFetch, values.file)

    yield call(norr.processing, resUploadReceipt, function *(){ })

    if(resUploadReceipt.type === 'error') {
      yield put(actions.reqSecretShopper(false))
      return;
    }

    values.receipt = resUploadReceipt.value
  }

  if(values.photos.length > 0) {
    const resUploadPhotos = yield call(postFilePhotosFetch, values.photos)
    yield call(norr.processing, resUploadPhotos, function *(){ })

    if(resUploadPhotos.type === 'error') {
      yield put(actions.reqSecretShopper(false))
      return;
    }

    values.photos = JSON.stringify(resUploadPhotos.value)
  }else if(Array.isArray(values.photos)){
    values.photos = JSON.stringify(values.photos)
  }
  
  values.rating = +values.rating

  const response = yield call(putReporterFetch, reportId, values)

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('report', false))
    yield call(getMyTasks)
  }, 'Отчет отправлен')

  yield put(actions.reqSecretShopper(false))

}

export function* uploadReceipt(payload: any): any{
  const {file} = payload;

  const response = yield call(postFileReceiptFetch, file)

  yield call(norr.processing, response, function *(){
    yield put(actions.setUrlReceipt(response.value))
    yield put(actions.reqUploadReceipt(true))
  })
}

export function* postCard(action: any): any{
  const {taskId, values} = action.payload;

  values.bankAlias = values.bank.alias;

  const response = yield call(postCardFetch, taskId, values)

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('card_number', false))
    yield call(getMyTasks)
  }, "Данные на получение вознаграждения отправлены")

}

export function* getReportTask(action: any): any{
  const taskId = action.payload.taskId;

  yield put(actions.reqTaskById(taskId, true))

  const response = yield call(getReportTaskFetch, taskId)

  yield call(norr.processing, response, function *(){
    yield put(actions.setReportTask(response.value)) 
  })

  yield put(actions.reqTaskById(taskId, false))

}

export function* checkSubscribe(): any{
  yield call(actions.reqSecretShopper, true);

  const response = yield call(checkSubscribeFetch)

  yield call(norr.processing, response, function *(){
    if(response.value.subscribe) 
      response.value.subscribe.telegram = response.value.telegram;
    
    yield put(actions.setSubscribe(response.value.subscribe))

    if(response.value?.telegram === false && response.value?.temporary) 
      yield put(setTelegramToken(response.value.temporary))
  })

  yield call(actions.reqSecretShopper, false);
}

export function* subscribeShopper(): any{
  const account = yield select(getAccount)
  const subscribe = yield select(reducers.getSubscribe)
  
  if(!subscribe) {
    const _subscribe = {
      accountId: account.accountId,
      access: true,
      isFavorites: false
    }
    const response = yield call(subscribeShopperFetch, _subscribe)

    yield call(postToken)
  
    yield call(norr.processing, response, function *(){
      if(response.value) {
        yield put(setTelegramToken(response.value))
        yield put(actions.showWindow('telegram', true))
      }else {
        yield call(checkSubscribe)
        yield put(actions.showWindow('subscribe', false))
      }
      
    })
  }else {
    subscribe.access = true
    yield put(actions.setSubscribe(subscribe))
    const response = yield call(putSubscribeShopperFetch, subscribe.id, subscribe)

    yield call(postToken)

    yield call(norr.processing, response, function *(){
      yield call(checkSubscribe)
    })
  }
  
}

export function* unSubscribeShopper(): any{
  const subscribe = yield select(reducers.getSubscribe)
  subscribe.access = false
  yield put(actions.setSubscribe(subscribe))

  const response = yield call(putSubscribeShopperFetch, subscribe.id, subscribe)

  yield call(norr.processing, response, function *(){
    yield call(checkSubscribe)
  })
}

export function* subscribeFavorites(action: any): any{
  const subscribe = yield select(reducers.getSubscribe)
  subscribe.isFavorites = action.payload.isFavorites

  const response = yield call(putSubscribeShopperFetch, subscribe.id, subscribe)

  yield call(norr.processing, response, function *(){})
}

export function* archiveMyTask(action: any): any {
  const response = yield call(archiveMyTaskFetch, action.payload.taskId);

  yield call(norr.processing, response, function *(){
    yield call(getMyTasks)
  })
}

export function* downloadReceipt(action: any): any {
  const taskId = action.payload.taskId;
  const response = yield call(getReportTaskFetch, taskId)

  yield call(norr.processing, response, function *(){
    try {
      download(`/api/admin/files/receipts/${response.value.payout.receipt}`, response.value.payout.receipt)
    } catch (error) {
      yield call(norr.error, 'Чек не найден')
    }
    
  })
}

export function* watch() {
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_TASK_BY_ID, getTaskById)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_TASKS, getTasks)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_MY_TASKS, getMyTasks)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_ARCHIVE_TASKS, getTaskArchive)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_TASKS_FILTER, getTasksByFilter)
  // yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_RAFFLE_TASKS, getRaffleTasks)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_POST_RAFFLE, postRaffle)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_POST_CANCEL, taskCancel)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_POST_MY_TASK, postMyTask)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_POST_REPORTER, postReporter)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_UPLOAD_RECEIPT, uploadReceipt)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_POST_CARD, postCard)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_REPORT_TASK, getReportTask)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_PUT_REPORTER, putReportTask)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_BANKS_LIST, getBankList)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_CHECK_SUBSCRIBE, checkSubscribe)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_POST_SUBSCRIBE, subscribeShopper)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_POST_UNSUBSCRIBE, unSubscribeShopper)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_POST_SUBSCRIBE_FAVORITE, subscribeFavorites)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_ARCHIVE_TASK_BY_ID, archiveMyTask)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_DOWNLOAD_RECEIPT, downloadReceipt)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_RANKS, getRanks)
}

export default function* Sagas() {
  yield fork(watch)
}
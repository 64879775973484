import * as constants from "domain/constants/secretShopper.constant";
import * as types from "domain/types/secretShopper.type";

export function getBankList(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_BANKS_LIST,
  }
}

export function getTasks(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_TASKS,
  }
}

export function getMyTasks(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_MY_TASKS,
  }
}

export function getTaskArchive(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_ARCHIVE_TASKS,
  }
}

export function getTasksFilter(
  page?: number, 
  isLoading?: boolean, 
  pageSize?: number
){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_TASKS_FILTER,
    payload: { 
      page: page ? page : 1, 
      isLoading: isLoading !== undefined ? isLoading : true ,
      pageSize: pageSize,
    },
  }
}

export function getTaskById(taskId: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_TASK_BY_ID,
    payload: {taskId}
  }
}

export function downloadReceipt(taskId: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_DOWNLOAD_RECEIPT,
    payload: {taskId}
  }
}

export function getRaffleTasks(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_RAFFLE_TASKS,
  }
}

export function getReportById(taskId: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_REPORT_TASK,
    payload: { taskId }
  }
}

export function setRaffle(taskId: string, cb: () => void ){
  return {
    type: constants.SECRET_SHOPPER_SAGA_POST_RAFFLE,
    payload: { taskId, cb }
  } 
}

export function setCancel(taskId: string, cb: () => void ){
  return {
    type: constants.SECRET_SHOPPER_SAGA_POST_CANCEL,
    payload: { taskId, cb }
  } 
}

export function archiveMyTask(taskId: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_ARCHIVE_TASK_BY_ID,
    payload: {taskId}
  }
}

export function setSubscribe(subscribe: types.ISubscribe){
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_SUBSCRIBE,
    payload: { subscribe }
  } 
}

export function setMyTask(taskId: string, cb: any){
  return {
    type: constants.SECRET_SHOPPER_SAGA_POST_MY_TASK,
    payload: { taskId, cb }
  }
}

export function postReporter(values: any){
  return {
    type: constants.SECRET_SHOPPER_SAGA_POST_REPORTER,
    payload: { values }
  }
}

export function putReporter(reportId: string, values: any, isFile: boolean){
  return {
    type: constants.SECRET_SHOPPER_SAGA_PUT_REPORTER,
    payload: { reportId, values, isFile }
  }
}

export function postCardNumber(taskId: string, values: any){
  return {
    type: constants.SECRET_SHOPPER_SAGA_POST_CARD,
    payload: { taskId, values }
  }
}

export function uploadReceipt(taskId: string, file: any){
  return { 
    type: constants.SECRET_SHOPPER_SAGA_UPLOAD_RECEIPT, 
    payload: { taskId, file } 
  }
}

export function checkSubscribe(){
  return { 
    type: constants.SECRET_SHOPPER_SAGA_CHECK_SUBSCRIBE,
  }
}

export function subscription(){
  return { 
    type: constants.SECRET_SHOPPER_SAGA_POST_SUBSCRIBE,
  }
}

export function unSubscription(){
  return { 
    type: constants.SECRET_SHOPPER_SAGA_POST_UNSUBSCRIBE,
  }
}

export function setFavorites(isFavorites: boolean){
  return { 
    type: constants.SECRET_SHOPPER_SAGA_POST_SUBSCRIBE_FAVORITE,
    payload: {isFavorites}
  }
}

export function setBankList(banks: types.IBankAlias[]): types.SecretShopperReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_BANK_LIST,
    payload: {banks}
  }
}

export function setCurrentTask(task: types.ITask): types.SecretShopperReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_TASK,
    payload: { task }
  }
}

export function setTasks(tasks: Array<types.ITask>): types.SecretShopperReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_TASKS,
    payload: { tasks }
  }
}

export function setMyTasks(tasks: Array<types.ITask>): types.SecretShopperReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_MY_TASKS,
    payload: { tasks }
  }
}

export function setTaskArchive(tasks: Array<types.ITask>): types.SecretShopperReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_ARCHIVE_TASKS,
    payload: { tasks }
  }
}

export function setRaffleTasks(tasks: Array<types.ITask>): types.SecretShopperReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_RAFFLE_TASKS,
    payload: { tasks }
  }
}

export function setReportTask(report: any): types.SecretShopperReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_REPORT_TASK,
    payload: { report }
  }
}

export function setFilterCity(cityId: string): types.SecretShopperReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_CITY_FILTER,
    payload: { cityId }
  }
}

export function showWindow(window: string, isShow: boolean ): types.SecretShopperReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_REDUCE_IS_SHOW_WINDOW,
    payload: {
      window: window,
      isShow: isShow
    }
  }
}

export function setUrlReceipt(url: string): types.SecretShopperReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_SET_URL_RECEIPT,
    payload: { url }
  }
}

export function getRanks(){
  return { 
    type: constants.SECRET_SHOPPER_SAGA_GET_RANKS,
  }
}

export function setRanks(ranks: types.IRank[]){
  return { 
    type: constants.SECRET_SHOPPER_REDUCE_SET_RANKS,
    payload: { ranks }
  }
}

export function reqUploadReceipt(isLoad: boolean): types.SecretShopperReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_UPLOAD_RECEIPT,
    payload: { loading: isLoad }
  }
}

export function reqSecretShopper(isLoad: boolean): types.SecretShopperReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqTaskById(id: string, isLoad: boolean): types.SecretShopperReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REQUEST_ITEM,
    payload: { 
      id: id,
      loading: isLoad 
    }
  }
}

